import { DynamicList } from '@/interfaces/model/general/dynamicList';

export const NewDynamicList: DynamicList = {
  id: 0,
  name: '',
  description: '',
  created_by: '',
  total: 0,
  last_count: 0,
  created_at: '',
  updated_at: '',
  deleted_at: null,
};
