import { LawId, RegulationId } from '@/lib/enum';
import { computed } from 'vue';

const showSection3CasTargets = [
  '100000000-200000000',
  '100000000-200000001',
  '100000000-200000002',
  '100000000-200000003',
  '100010000-200010010',
  '100010000-200010011',
  '100020000-200020016',
  '100020000-200020004',
  '100020000-200020002',
  '100020000-200020003',
  '100020000-200020005',
  '100020000-200020006',
  '100020000-200020007',
  '100020000-200020008',
  '100020000-200020009',
  '100020000-200020010',
  '100020000-200020014',
  '100020000-200020025',
  '100020000-200020027',
  '100020000-200020028',
  '100030000-all',
  '100040000-all',
  '100020000-200020032',
  '100020000-200020037',
];
const showSection3MaterialTargets = [
  '100000000-200000000',
  '100000000-200000001',
  '100000000-200000002',
  '100000000-200000003',
  '100010000-200010010',
  '100010000-200010011',
  '100020000-200020016',
  '100020000-200020004',
  '100020000-200020002',
  '100020000-200020003',
  '100020000-200020005',
  '100020000-200020006',
  '100020000-200020007',
  '100020000-200020008',
  '100020000-200020009',
  '100020000-200020010',
  '100020000-200020025',
  '100020000-200020027',
  '100020000-200020028',
  '100030000-all',
  '100040000-all',
  '100020000-200020032',
  '100020000-200020037',
];

export const useDynamicListComposable = () => {
  const includesShowSection3Cas = (lawRegulationIds: string[]): boolean => {
    return lawRegulationIds.some((lawRegulationId) =>
      showSection3CasTargets.includes(`${lawRegulationId}`)
    );
  };

  const includesShowSection3Material = (
    lawRegulationIds: string[]
  ): boolean => {
    return lawRegulationIds.some((lawRegulationId) =>
      showSection3MaterialTargets.includes(`${lawRegulationId}`)
    );
  };

  const getLawText = (
    lawId: string | number,
    mode: 'short' | 'long' | 'full'
  ) => {
    const id = typeof lawId === 'string' ? Number.parseInt(lawId, 10) : lawId;
    if (Number.isNaN(id)) {
      throw new Error(`lawId is not number. lawId=${lawId}`);
    }
    const law = LawId.fromId(id);
    if (mode === 'long') {
      return law.textCheckListRequest.includes(' ; ')
        ? law.textCheckListRequest.split(' ; ')[0]
        : law.textCheckListRequest;
    }
    if (mode === 'full') {
      return law.textCheckListRequest.includes(' ; ')
        ? `${law.textCheckListRequest.split(' ; ')[0]}（${
            law.textCheckListRequest.split(' ; ')[1]
          }）`
        : law.textCheckListRequest;
    }
    return law.textCheckListRequest.includes(' ; ')
      ? law.textCheckListRequest.split(' ; ')[1]
      : law.textCheckListRequest;
  };

  const getRegulationText = (
    regulationId: string | number,
    isShortName = true
  ) => {
    const id =
      typeof regulationId === 'string'
        ? Number.parseInt(regulationId, 10)
        : regulationId;
    if (Number.isNaN(id)) {
      throw new Error(
        `regulationId is not number. regulationId=${regulationId}`
      );
    }
    const regulation = RegulationId.fromId(id);
    if (!isShortName) {
      return regulation.textCheckListRequest.includes(' ; ')
        ? regulation.textCheckListRequest.split(' ; ')[0]
        : regulation.textCheckListRequest;
    }
    return regulation.textCheckListRequest.includes(' ; ')
      ? regulation.textCheckListRequest.split(' ; ')[1]
      : regulation.textCheckListRequest;
  };

  const joinLawRegulationText = (lawText: string, regulationText: string) => {
    return `${lawText}: ${regulationText}`;
  };

  const getLawRegulationText = (lawRegulationId: string) => {
    const [lawId, regulationId] = lawRegulationId.split('-');
    return joinLawRegulationText(
      getLawText(lawId, 'short'),
      getRegulationText(regulationId)
    );
  };

  const lawRegulationList = computed(() => {
    const lawRegulationMap = new Map<
      number,
      { showAll?: boolean; regulationIds: number[] }
    >();
    // 化審法
    lawRegulationMap.set(100000000, {
      regulationIds: [
        200000005, 200000007, 200000008, 200000009, 200000010, 200000011,
        200000000, 200000001, 200000002, 200000003, 200000004, 200000006,
      ],
    });
    // 化管法
    lawRegulationMap.set(100010000, {
      regulationIds: [
        200010005, 200010007, 200010008, 200010009, 200010010, 200010011,
        200010000, 200010001, 200010002, 200010003, 200010004, 200010006,
      ],
    });
    // 安衛法 | 労安法
    lawRegulationMap.set(100020000, {
      regulationIds: [
        200020031, 300020000, 300020001, 300020002, 300020006, 300020005,
        200020016, 300020011, 300020010, 300020009, 300020008, 300020007,
        200020004, 300020003, 300020004, 300020012, 200020000, 200020001,
        200020002, 200020003, 200020005, 200020006, 200020007, 200020008,
        200020009, 200020010, 200020011, 200020012, 200020013, 200020014,
        200020015, 200020017, 200020018, 200020019, 200020020, 200020021,
        200020022, 200020023, 200020024, 200020025, 200020026, 200020027,
        200020028, 200020029, 200020030, 200020032, 200020037,
      ],
    });
    // 毒劇法 全て あり
    lawRegulationMap.set(100030000, {
      showAll: true,
      regulationIds: [
        200030000, 200030001, 200030002, 200030003, 200030004, 200030005,
        200030006, 200030007, 200030008, 200030009, 200030010, 200030011,
        200030012,
      ],
    });
    // 消防法 全て あり
    lawRegulationMap.set(100040000, {
      showAll: true,
      regulationIds: [
        400040009, 400040006, 400040007, 400040008, 500040000, 200040008,
        200040000, 200040001, 200040002, 200040003, 200040004, 200040005,
        200040007, 200040009, 200040010, 200040011, 200040012, 200040014,
        500040001, 300040001, 300040002, 300040003, 300040004, 300040005,
        300040000, 400040000, 400040001, 400040002, 400040003, 400040004,
        400040005,
      ],
    });
    // 外為法 全て あり
    lawRegulationMap.set(100050000, {
      showAll: true,
      regulationIds: [200050000, 200050001],
    });
    // オゾン層保護法 全て あり
    lawRegulationMap.set(100060000, {
      showAll: true,
      regulationIds: [200060001, 200060000],
    });
    // 大気汚染防止法 全て あり
    lawRegulationMap.set(100070000, {
      showAll: true,
      regulationIds: [
        200070001, 200070000, 200070002, 200070003, 200070004, 200070005,
        200070006, 200070007, 200070008, 200070009, 200070010,
      ],
    });
    // 水質汚濁防止法 全て あり
    lawRegulationMap.set(100080000, {
      showAll: true,
      regulationIds: [
        200080004, 200080005, 200080000, 200080001, 200080002, 200080003,
      ],
    });
    // 土壌汚染対策法
    lawRegulationMap.set(100090000, {
      regulationIds: [200090000, 200090001, 200090002, 200090003],
    });
    // 食品衛生法 全て あり
    lawRegulationMap.set(100100000, {
      showAll: true,
      regulationIds: [
        200100002, 200100007, 200100006, 200100005, 200100004, 200100003,
        200100000, 200100001,
      ],
    });
    // 海洋汚染防止法
    lawRegulationMap.set(100110000, {
      regulationIds: [200110000],
    });
    // 航空法 全て あり
    lawRegulationMap.set(100130000, {
      showAll: true,
      regulationIds: [
        200130002, 200130006, 200130001, 200130000, 200130009, 200130008,
        200130005, 200130004, 200130003, 300130000, 200130007,
      ],
    });
    // 港則法
    lawRegulationMap.set(100140000, {
      regulationIds: [200140000],
    });
    // 廃棄物処理法 | 廃掃法 全て あり
    lawRegulationMap.set(100150000, {
      showAll: true,
      regulationIds: [200150000, 200150001],
    });
    // 家庭用品規制法 全て あり
    lawRegulationMap.set(100170000, {
      showAll: true,
      regulationIds: [200170001, 200170000],
    });
    // 水道法
    lawRegulationMap.set(100230000, {
      regulationIds: [200230000],
    });
    // 下水道法
    lawRegulationMap.set(100240000, {
      regulationIds: [200240000],
    });
    // 労働基準法
    lawRegulationMap.set(100250000, {
      regulationIds: [200250000],
    });

    return Array.from(lawRegulationMap)
      .map(([lawId, data]) => {
        const array: { value: string; text: string }[] = [];
        if (data.showAll) {
          array.push({
            value: `${lawId}-all`,
            text: joinLawRegulationText(getLawText(lawId, 'short'), '全て'),
          });
        }
        data.regulationIds.forEach((regulationId) => {
          array.push({
            value: `${lawId}-${regulationId}`,
            text: joinLawRegulationText(
              getLawText(lawId, 'short'),
              getRegulationText(regulationId)
            ),
          });
        });
        return array;
      })
      .flat(1);
  });

  return {
    lawRegulationList,
    getLawRegulationText,
    getLawText,
    getRegulationText,
    showSection3CasTargets,
    showSection3MaterialTargets,
    includesShowSection3Cas,
    includesShowSection3Material,
  };
};
